import { RESPONSE_STATUSES } from '@/components/shared/constants';

export function get(url, token) {
    return new Promise((resolve, reject) => {
        const xhttp = new XMLHttpRequest();
        xhttp.open('GET', url, true);
        xhttp.setRequestHeader('Authorization', `Bearer ${token}`);
        xhttp.send();
        xhttp.onreadystatechange = () => {
            if(xhttp.readyState === XMLHttpRequest.DONE) {
                if (xhttp.status === RESPONSE_STATUSES.SUCCESS 
                    && xhttp.responseText !== undefined) {
                    const response = JSON.parse(xhttp.responseText);
                    resolve(response);
                }
                else {
                    reject(xhttp.status);
                }
            }
        };
        xhttp.onerror = () => reject(xhttp.status);
    });
}

export function post(url, token, params) {
    return new Promise((resolve, reject) => {
        const xhttp = new XMLHttpRequest();
        xhttp.open('POST', url, true);
        xhttp.setRequestHeader('Authorization', `Bearer ${token}`);
        xhttp.setRequestHeader('Content-Type', 'application/json');
        xhttp.send(JSON.stringify(params));
        xhttp.onreadystatechange = () => {
            if(xhttp.readyState == XMLHttpRequest.DONE) {
                if (xhttp.status === RESPONSE_STATUSES.SUCCESS) {
                    resolve(xhttp.response);
                }
                else {
                    reject(xhttp.status);
                }
            }
        };
        xhttp.onerror = () => reject(xhttp.status);
    });
}