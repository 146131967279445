<template>
  <div class="modalOverlay">
    <div
      class="modalFrame"
      :class="[dialogClasses, !hasFooterSlot ? 'no-footer' : '']">
      <div
        v-if="dialogType !== 'loading'"
        class="modalContent">
        <button
          v-if="!hideHeaderClose"
          class="wk-icon-filled-close icon"
          @click="onClose" />
        <console-notification
          class="modalMessage"
          :notification-mode="dialogType">
          <template #title><slot name="header">&nbsp;</slot></template>
          <slot />
        </console-notification>
      </div>
      <div
        v-if="dialogType === 'loading'"
        class="modal-loading">
        <div class="wk-docs-example-loading-circular">
          <div class="wk-loading-circular-container wk-loading-light-overlay">
            <div class="loadingSpinner">
              <span class="wk-loading-circular-icon wk-icon-filled-spinner-alt wk-spin" />
            </div>
            <div class="loadingText">
              <span class="wk-loading-circular-label">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="hasFooterSlot"
        class="modalFooter">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConsoleNotification from './ConsoleNotification.vue';

const dialogStyles = {
    error: 'modal-error',
    info: 'modal-info',
    warn: 'modal-warn',
    success: 'modal-success',
    loading: 'modal-loading',
    basic: 'modal-basic',
    support: 'modal-support'
};
export default {
    components: { ConsoleNotification },
    props: {
        dialogType: {
            type: String,
            default: 'error'
        },
        // When true will hide the 'X' close button in header
        hideHeaderClose: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialogClasses() {
            const typeStyle = dialogStyles[this.dialogType] || '';
            return `${typeStyle}`;
        },
        hasFooterSlot() {
            const ss = this.$scopedSlots;
            const footerNodes = ss && ss.footer && ss.footer();
            return footerNodes && footerNodes.length;
        }
    },
    methods: {
        ...mapActions([
            'hideModal'
        ]),
        onClose() {
            this.hideModal();
        }
    }
};
</script>

<style scoped lang="scss">
.icon {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  color: $MODAL-CLOSE-BUTTON;
  border: transparent;
  line-height: 0;
  margin: 16px;
  padding: 0px;
  height: 16px;
  font-size: 16px;
  z-index: 1;
  cursor: pointer;
}

.modalFrame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    left: 0;
    background-color: $MODAL-BACKGROUND;
    z-index: inherit;
    margin: auto;
    width: 30vw;
    border-style: solid;
    border-width: 1px;
    border-left-width: 3px;
    height: fit-content;

  @media only screen and (min-width: 768px) {
    min-width: 500px;
    display: block;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: $MODAL-BLUR-BACKGROUND;
  width:100%;
  height:100%;
  z-index: 10000;
}

.modalFooter {
  margin-bottom: 16px;
  margin-left: 52px;
  margin-top: 8px;
}

.modal- {
  &info {
    border-color: $MODAL-INFO;
    color: $MODAL-INFO;
  }

  &warn {
    border-color: $MODAL-WARN;
    color: $MODAL-WARN;
  }
  
  &error {
    border-color: $MODAL-ERROR;
    color: $MODAL-ERROR;
  }

  &success {
    border-color: $MODAL-SUCCESS;
    color: $MODAL-SUCCESS;
  }

  &basic, &support {
    border: none;
  }

  &loading {
    display: flex;
    justify-content: center;
    border-color: transparent;
    height: 74px;
    align-items: center;
  }
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 25px;
}

.loadingText {
  color: $MODAL-LOADING;
}

.modalMessage {
  border: 0;
  padding-top: 15px;
  margin-top: 0;
}
</style>