<template>
  <div class="tableFrame">
    <div class="tableTitle">Seat Count</div>
    <table>
      <tr>
        <th
          v-for="header in headers"
          :key="header"
          scope="col">{{ header }}</th>
      </tr>
      <tr>
        <td col-id="purchased">{{ seatCount }}</td>
        <td col-id="active">{{ activeUsers }}</td>
        <td col-id="unused">{{ seatCount - activeUsers }}</td>
        <td col-id="disabled">{{ disabledUsers }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { STATUS } from '../shared/constants';

export default {
    data() {
        return {
            headers: [
                'Purchased',
                'Active',
                'Unused',
                'Disabled'
            ]
        };
    },
    computed: {
        ...mapGetters('account', [
            'seatCount',
            'activeUsers',
            'disabledUsers'
        ])
    },
    mounted() {
        this.$root.$on('updatedUserStatus', (status, numSelected) => this.updateMetrics(status, numSelected));
        
    },
    beforeDestroy() {
        this.$root.$off('updatedUserStatus', (status, numSelected) => this.updateMetrics(status, numSelected));
    },
    methods: {
        ...mapActions('account', [
            'getAccount',
            'setUserStatuses'
        ]),
        updateMetrics(status, numSelected) {
            let activeUsersCount = this.activeUsers;
            let disabledUserCount = this.disabledUsers;
            const selectedUsers = status === STATUS.ACTIVE ? numSelected : (numSelected * -1);
            activeUsersCount += selectedUsers;
            disabledUserCount -= selectedUsers;
            const statuses = {activeUsers: activeUsersCount, disabledUsers: disabledUserCount};
            this.setUserStatuses(statuses);
        }
    }
};
</script>

<style lang="scss">
.tableFrame {
    display: flex;
    flex-direction: column;
    margin: 32px auto 32px 0px;
    gap: 5px;
}

.tableTitle {
    font-family: "Noto Sans Light", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

table {
    border-spacing: 0px;
    max-width: 577px;
    border: 1px solid $SEAT-COUNT-TABLE-BORDER;
    text-align: left;
}

th {
    border-bottom: 1px solid $SEAT-COUNT-HEADER-BOTTOM;

    &:nth-of-type(1) {
        background-color: $SEAT-COUNT-HEADER-COL1-BGCLR;
    }

    &:nth-of-type(n+2) {
        border-left: 1px solid $SEAT-COUNT-HEADER-COL-BGCLR;
    }
}

th, td {
    padding: 2px 16px 2px 16px;
    font-size: 14px;
    font-weight: normal;
    height: 18px;
    line-height: 18px;
    width: 111px;
}
</style>