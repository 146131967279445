<template>
  <div class="userManagementError">
    <support-tag />
    <console-notification :notification-mode="'error'">
      <template #title>{{ errorTitle }}</template>
      <template #default>{{ errorBody }}</template>
      <template #footer>
        <console-button
          :button-type="'button'"
          class="loginRedirectButton"
          :button-style="'buttonText'"
          @on-click="redirectToLogin()"
        >
          Back to Login
        </console-button>
      </template>
    </console-notification>
  </div>
</template>

<script>
import ConsoleNotification from './shared/ConsoleNotification.vue';
import ConsoleButton from './shared/ConsoleButton.vue';
import SupportTag from './shared/SupportTag.vue';
import { mapGetters } from 'vuex';

export default {
    components: { ConsoleNotification, ConsoleButton, SupportTag },
    computed: mapGetters ([
        'errorTitle',
        'errorBody'
    ]),
    methods: {
        redirectToLogin() {
            window.location.assign('/login');
        }
    }
};
</script>

<style lang="scss" scoped>
.userManagementError {
	max-width: 960px;
	margin: 120px auto;
	padding-bottom: 150px;
	text-align: center;
}

.notification {
	display: inline-block;
	width: fit-content;
}

.notificationFooter {
	text-align: left;
}

.loginRedirectButton {
	padding-top: 15px;
	padding-bottom: 0px;
	padding-left: 0px;
	display: -webkit-box;
	text-align: left;
	margin: 0px;
	border: 0px;
}
</style>