<template>
  <input
    :type="inputType"
    v-bind="$attrs"
    :placeholder="placeholder"
    :value="value"
    autocomplete="off"
    autocapitalize="off"
    autocorrect="off"
    maxlength="50"
    @input="onInput($event)">    
</template>

<script>
export default {
    props: {
        inputType: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: String
    },
    methods:{
        onInput($event) {
            this.$emit('input', $event.target.value);
        }
    }
};
</script>

