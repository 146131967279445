<template>
  <div
    v-if="modalSupportTag"
    class="support">
    <console-modal
      v-if="isModalVisible" 
      :dialog-type="modalType"
      :hide-header-close="hideHeaderClose">
      <template #header>{{ modalTitle }}</template>
      <div class="supportTag">
        <div>
          Support Tag
          <div class="supportTagSection">
            <div style="margin-right: -3px">[</div>
            <div 
              v-for="section in generateSupportTag()"
              :id="section.name"
              :key="section.value">
              <div 
                v-if="section.name === 'adminUtdId'"
                style="margin-right: -4px">(</div>
              <div 
                v-if="section !== curSupportTag.at(0)
                  && section.name !== 'nonActiveAccountIds'
                  && section.name !== 'adminUtdId'"> - </div>
              {{ section.value }}
              <div
                v-if="section.name === 'activeAccountId'
                  && numAccounts() > 1"
                style="margin-left: -3px">,</div>
              <div 
                v-if="section.name === 'adminUtdId'" 
                style="margin-left: -4px; margin-right: 4px">)</div>
            </div>
            <div style="margin-left: -3px">]</div>
            <div
              v-if="supportTag && supportTag.technicalInfo"
              id="technicalInfo">
              <div> -</div>
              {{ supportTag.technicalInfo }}</div>
          </div>
        </div>
        <div>
          Release
          <div class="releaseSection">
            <div><div>Backend Read Service </div><div id="backendReadVersion">{{ supportTag.backendReadVersion }}</div></div>
            <div><div>Backend Write Service </div><div id="backendWriteVersion">{{ supportTag.backendWriteVersion }}</div></div>
            <div><div>Client </div><div id="clientVersion">{{ supportTag.clientVersion }}</div></div>
          </div>
        </div>
        <div>
          Licensed to <div
            id="licenseName"
            class="licenseSection">{{ getAdminName() }}</div>
        </div>
      </div>
    </console-modal>
  </div>
</template>

<script>
import ConsoleModal from './ConsoleModal.vue';
import { mapActions, mapGetters } from 'vuex';
import Cookies from 'js-cookie';

export default {
    components: { ConsoleModal },
    data() {
        return {
            hideHeaderClose: false,
            curSupportTag: []
        };
    },
    computed: {
        ...mapGetters ([
            'isModalVisible',
            'modalTitle',
            'modalType',
            'modalSupportTag'
        ]),
        ...mapGetters ('supportTag', [
            'supportTag',
            'sessionId',
            'sessionStartTime'
        ]),
        ...mapGetters ('admin', [
            'adminFirstName',
            'adminLastName',
            'adminUtdId',
            'allManagedAccounts'
        ]),
        ...mapGetters ('account', [
            'id',
            'businessUnit'
        ])
    },
    async beforeMount() {
        this.hideModal();
    },
    methods: {
        ...mapActions([
            'showModal',
            'hideModal'
        ]),
        getAdminName() {
            return `${this.adminFirstName ? `${this.adminFirstName} 
            `: ''}${this.adminLastName ? this.adminLastName : ''}`;
        },
        generateNonActiveAccountIds() {
            let currAccounts = '';
            this.allManagedAccounts.filter((account) => 
                account.accountId != this.id).forEach((account) => currAccounts += (`, ${account.accountId}`));
            return currAccounts.substring(2);
        },
        numAccounts() {
            return this.allManagedAccounts.length;
        },
        generateSupportTag() {
            const supportTagSections = [
                {name: 'server', value: this.supportTag.server},
                {name: 'ip', value: this.supportTag.ip},
                {name: 'sessionId', value: Cookies.get('sessionId')},
                {name: 'accessType', value: this.supportTag.accessType},
                {name: 'adminUtdId', value: this.adminUtdId},
                {name: 'activeAccountId', value: `ACTIVE(${this.id})`},
                {name: 'nonActiveAccountIds', value: this.generateNonActiveAccountIds()},
                {name: 'businessUnit', value: this.businessUnit},
                {name: 'startTime', value: this.formatTime(Cookies.get('sessionStartTime'))}
            ];
            this.curSupportTag = supportTagSections.filter(s => !!s.value && s.value !== '' && s.value != 0);
            return this.curSupportTag;
        },
        formatTime(time) {
            const rawStartTime = new Date(JSON.parse(time));

            const year = rawStartTime.getFullYear();
            const month = this.formatDigits(rawStartTime.getMonth() + 1);
            const date = this.formatDigits(rawStartTime.getDate());

            const hr = this.formatDigits(rawStartTime.getUTCHours());
            const min = this.formatDigits(rawStartTime.getUTCMinutes());
            const sec = this.formatDigits(rawStartTime.getUTCSeconds());

            return `${year}${month}${date}-${hr}:${min}:${sec}GMT`;
        },
        formatDigits(num) {
            return num < 10 ? `0${num}` : num;
        }
    }
};
</script>

<style scoped lang="scss">
.supportTag {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 27px;
    font-size: 13px;
    color: $SUPPORT-SUBHEADER-TEXT-COL;

    .supportTagSection {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      white-space: pre-wrap;
    }

    #adminUtdId {
      margin: -2px;
    }

    div {
        display: inherit;
        flex-direction: inherit;
        align-items: inherit;

        .releaseSection div {
          display: flex;
          flex-direction: row;
          white-space: pre;
        }
        
        div {
            text-align: center;
            font-size: 14px;
            color: $SUPPORT-TEXT-COL;
        }
    }
}
</style>