export const STATUS = Object.freeze({
    ACTIVE: 'Active',
    DISABLED: 'Disabled',
    ACTION: {Disabled: 'Disable', Active: 'Re-enable'}
});

/**
 * This regular expression outlines our rules for a syntactically correct email address.
 * Allows for anything before and after an asperand (@), assuming the domain consists of valid dot-atoms.
 * Also prevents the domain from beginning/ending with a dot.
 * (Omits whitespace, pipes, apostrophes, and multiple @)
*/
export const EMAIL_REGEX = /^([^\s|'@]+)@([^\s|'@.]+)(\.[^\s|'@.]+)+$/;

/* A regular expression to identify potential XSS Injection */
export const XSS_INJECTION_PATTERN = /<\/?[^>]*(img|script|href|on[^>\s]+|src|iframe|input|background|dynsrc|lowsrc|style|size|meta|url)[^>]*>/ig;

export const RESPONSE_STATUSES = Object.freeze({
    SUCCESS: 200, 
    VALIDATION_FAILED: 400,
    AUTHENTICATION_FAILED: 401, 
    AUTHORIZATION_FAILED: 403,
    INVALID_STATE: 409, 
    INTERNAL_SERVER: 500
});

export const SORT_BY = Object.freeze({
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    email: 'EMAIL',
    status: 'STATUS',
    startDate: 'START_DATE',
    endDate: 'END_DATE'
});

export const TAB_TITLES = Object.freeze({
    errorTitle: 'Error'
});

export const ERROR_MESSAGE = Object.freeze({
    AUTHORIZATION_FAILED: {title: 'Login Error', body: 'This email address is not valid. Please try again or contact your organization.'},
    INTERNAL_SERVER: {title: 'Error', body: 'Action has failed due to system error. Please try again later.'},
    DEFAULT: {title: 'Unknown Error', body: 'An unknown error occurred. Please attempt your action again or contact your organization.'}
});

export const MODAL_MESSAGE = Object.freeze({
    SUCCESS(numSelected, status) { 
        return {title: 'Success', 
            message: `You have set ${numSelected} user${numSelected > 1 ? 's' : ''} to ${status.toLowerCase()}.`, 
            type: 'success', 
            isFooter: false};
    },
    SUCCESS_REFRESH(numSelected, status) {
        return {title: 'Success - Please Refresh',
            message: `You have set ${numSelected} user${numSelected > 1 ? 's' : ''} to ${status.toLowerCase()}. Please refresh the page to see the latest changes.`,
            type: 'success',
            isFooter: false };
    },
    SEATS_EXCEEDED(selectedUserIds, seatCount, activeUsers) {
        return {title: 'Maximum Seats Exceeded',
            message: `You have selected ${selectedUserIds.length} user${selectedUserIds.length > 1 ? 's' : ''}. ${seatCount - activeUsers} seat${seatCount - activeUsers === 1 ? ' is' : 's are'} available. ${seatCount - activeUsers > 0 ? 'Please reduce the number of selected users.' : 'Seats can be increased by purchasing more or disabling users. Please contact your account holder for more information.'}`,
            type: 'error',
            isFooter: false
        };
    },
    LOADING() {
        return {type: 'loading',
            isFooter: false 
        };
    },
    ERROR(action) {
        return {title: 'Error',
            message: `${action} has failed due to system error. Please try again later.`,
            type: 'error',
            isFooter: false 
        };
    },
    CONFIRMATION(status, selectedUserIds) {
        return {title: `${status} User`,
            message: `Are you sure you want to ${status.toLowerCase()} ${selectedUserIds.length} user${selectedUserIds.length > 1 ? 's' : ''}?`,
            type: 'warn',
            isFooter: true
        };
    },
    MULTIPLE_STATUSES() {
        return {title: 'Multiple Statuses Selected',
            message: 'Please select users with the same status to make any changes.',
            type: 'error',
            isFooter: false 
        };
    },
    SUPPORT_TAG() {
        return {title: 'UMC Support Tag',
            type: 'support',
            isFooter: false,
            isSupportTag: true
        };
    },
    INVALID_SEARCH_INPUT() {
        return {title: 'Invalid Search',
            message: 'Please enter a different search term.',
            type: 'error',
            isFooter: false
        };
    }
});

export const FOOTER_LINKS = Object.freeze({
    WK_LOGO: 'https://www.wolterskluwer.com/en/health',
    TERMS_OF_USE: 'https://www.wolterskluwer.com/en/know/clinical-effectiveness-terms',
    POLICIES: 'https://www.wolterskluwer.com/en/solutions/uptodate/policies-legal',
    FACEBOOK: 'https://www.facebook.com/UpToDateEBM',
    TWITTER: 'https://twitter.com/UpToDate',
    LINKEDIN: 'https://www.linkedin.com/company/uptodate?trk=hb_tab_compy_id_21924',
    YOUTUBE: 'https://www.youtube.com/uptodateebm',
    CONTACT: 'https://www.wolterskluwer.com/en/solutions/uptodate/contact-us',
    ABOUT: 'https://www.wolterskluwer.com/en/solutions/uptodate/about',
    UTD_NEWS: 'https://www.wolterskluwer.com/en/solutions/uptodate/about#news',
    WK_HEALTH: 'https://www.wolterskluwer.com/en/health',
    UPTODATE: 'https://www.wolterskluwer.com/en/solutions/uptodate/why-uptodate',
    MEDI: 'https://www.wolterskluwer.com/en/solutions/medi-span'
});

export const USER_UPDATE = Object.freeze({
    TIMEOUT: 1500,
    ATTEMPTS: 6
});

export const COOKIE_CONFIGURATIONS = Object.freeze({
    GET(startTime) {
        return { 
            secure: true,
            expires: new Date(startTime + 60 * 60 * 1000),
            sameSite:'strict'
        };
    }
});

export const ENVIRONMENT_VARIABLES = Object.freeze({
    USERMANAGEMENT_SERVICE: process.env.VUE_APP_SERVICES_BASE_URL,
    TOTAL_NUMBER_OF_REQUESTED_USERS: process.env.VUE_APP_TOTAL_USERS_REQUESTED,
    PAGINATION_PAGE_SIZE: process.env.VUE_APP_PAGINATION_PAGE_SIZE
});

export const URLS = Object.freeze({
    ACCOUNT_URL : `${process.env.VUE_APP_SERVICES_BASE_URL}accounts/json`, 
    LOGGED_IN_USER_URL :`${process.env.VUE_APP_SERVICES_BASE_URL}admin/json`, 
    INFO_URL: 'management/info', 
    SUPPORT_TAG: `${process.env.VUE_APP_SERVICES_BASE_URL}support/versions/json`
});