import { get, post } from '@/api/rest.utility';
import { ENVIRONMENT_VARIABLES, STATUS } from '@/components/shared/constants';
import Cookies from 'js-cookie';

export const SET_USERS = 'SET_USERS';
export const SET_USER_STATUS = 'SET_USER_STATUS';
export const SET_SELECTED_USERS = 'SET_SELECTED_USERS';
export const SET_FIRSTNAME_SEARCH = 'SET_FIRSTNAME_SEARCH';
export const SET_LASTNAME_SEARCH = 'SET_LASTNAME_SEARCH';
export const SET_EMAIL_SEARCH = 'SET_EMAIL_SEARCH';
export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';

export const users = {
    namespaced: true,
    state: () => ({
        users: {},
        selectedUsers: [],
        pageNumber: 0,
        totalNumberOfRequestedUsers: ENVIRONMENT_VARIABLES.TOTAL_NUMBER_OF_REQUESTED_USERS,
        paginationPageSize: ENVIRONMENT_VARIABLES.PAGINATION_PAGE_SIZE,
        userStatus: STATUS.ACTIVE,
        userSearch: {
            firstName: '',
            lastName: '',
            email: ''
        }, 
        sortBy: '',
        sortOrder: ''  
    }),
    getters: {
        users: state => state.users.users,
        userStatus: state => state.userStatus,  
        firstNameSearch: state => state.userSearch.firstName,
        lastNameSearch: state => state.userSearch.lastName,
        emailSearch: state => state.userSearch.email,
        pageNumber: state => state.pageNumber,
        selectedUsers: state => state.selectedUsers,
        selectedUserIds: state => {
            return state.selectedUsers.map(user => {
                return user.utdId;
            });
        },
        selectedActiveUsers: state => {
            return state.selectedUsers.reduce((selectedActive, node) => {
                return selectedActive || node.status === STATUS.ACTIVE;
            }, false);
        },
        selectedDisabledUsers: state => {
            return state.selectedUsers.reduce((selectedActive, node) => {
                return selectedActive || node.status === STATUS.DISABLED;
            }, false);
        },
        paginationPageSize: state => state.paginationPageSize,
        sortBy: state => state.sortBy,
        sortOrder: state => state.sortOrder,
        getUsersUrl: (state, getters, rootState, rootGetters) => `${ENVIRONMENT_VARIABLES.USERMANAGEMENT_SERVICE}accounts/${rootGetters['admin/selectedAccountId']}`
            + `/users/json?page=${state.pageNumber}&page_size=${state.totalNumberOfRequestedUsers}`
            + `&user_status=${STATUS.ACTIVE}`,
        getFilteredUsersUrl: (state, getters, rootState, rootGetters) => `${ENVIRONMENT_VARIABLES.USERMANAGEMENT_SERVICE}accounts/${rootGetters['admin/selectedAccountId']}`
            + `/users/search/json?page=${state.pageNumber}&page_size=${state.totalNumberOfRequestedUsers}` 
            + `&sort_by=${state.sortBy}&sort_order=${state.sortOrder}`
            + `&first_name=${state.userSearch.firstName}&last_name=${state.userSearch.lastName}`
            + `&email=${state.userSearch.email}`
            +`&user_status=${state.userStatus}`,
        updateUsersUrl: (state, getters, rootState, rootGetters) => `${ENVIRONMENT_VARIABLES.USERMANAGEMENT_SERVICE}accounts/${rootGetters['admin/selectedAccountId']}`
        + '/users/status/json'                
    },
    mutations: { 
        [SET_USER_STATUS]: (state, userStatus) => state.userStatus = userStatus,
        [SET_USERS]: (state, users) => state.users = users,
        [SET_SELECTED_USERS]: (state, selectedUsers) => state.selectedUsers = selectedUsers,
        [SET_FIRSTNAME_SEARCH]: (state, firstNameSearch) => state.userSearch.firstName = firstNameSearch,
        [SET_LASTNAME_SEARCH]: (state, lastNameSearch) => state.userSearch.lastName = lastNameSearch,
        [SET_EMAIL_SEARCH]: (state, emailSearch) => state.userSearch.email = emailSearch,
        [SET_SORT_BY]: (state, sortBy) => state.sortBy = sortBy,
        [SET_SORT_ORDER]: (state, sortOrder) => state.sortOrder = sortOrder
    },
    actions: {
        async getUsers({ commit, getters }) {
            const url = getters.getUsersUrl;
            const token = Cookies.get('authToken');
            await get(url, token).then((users) => {
                commit(SET_USERS, users);
            });
        },

        async getFilteredUsers({ commit, getters }) {
            const url = getters.getFilteredUsersUrl;
            const token = Cookies.get('authToken');
            await get(url, token).then((filteredUsers) => {
                commit(SET_USERS, filteredUsers);
                commit(SET_SELECTED_USERS, []);
            });
        },
 
        async updateUsers({ getters }, data) {
            const url = getters.updateUsersUrl;
            const token = Cookies.get('authToken');
            const params = {
                'userStatus': data.status,
                'utdIds': getters.selectedUserIds
            };
            await post(url, token, params);
        },

        clearUserStore({ commit }) {
            commit(SET_USERS, []);
            commit(SET_SORT_BY, '');
            commit(SET_SORT_ORDER, '');
            commit(SET_USER_STATUS, STATUS.ACTIVE);
            commit(SET_FIRSTNAME_SEARCH, '');
            commit(SET_LASTNAME_SEARCH, '');
            commit(SET_EMAIL_SEARCH, '');
            commit(SET_SELECTED_USERS, []);
        }
    }       
};