import { ENVIRONMENT_VARIABLES } from '@/components/shared/constants';
import { get } from '@/api/rest.utility';
import Cookies from 'js-cookie';

export const SET_ACCOUNT_NAME = 'SET_ACCOUNT_NAME';
export const SET_ACCOUNT_BUSINESS_UNIT = 'SET_ACCOUNT_BUSINESS_UNIT';
export const SET_BACKEND_ACCOUNT_ID = 'SET_BACKEND_ACCOUNT_ID';
export const SET_ACTIVE_USERS = 'SET_ACTIVE_USERS';
export const SET_DISABLED_USERS = 'SET_DISABLED USERS';
export const SET_SEAT_COUNT = 'SET_SEAT_COUNT';
export const account = {
    namespaced: true,
    state: () => ({ 
        id: 0,
        name: '',
        businessUnit: '',
        seatCount: 0,
        activeUsers: 0,
        disabledUsers: 0 
    }),
    getters: {
        id: state => state.id,
        businessUnit: state => state.businessUnit,
        name: state => state.name,
        seatCount: state => state.seatCount,
        activeUsers: state => state.activeUsers,
        disabledUsers: state => state.disabledUsers,
        getAccountUrl:(state, getters, rootState, rootGetters) => `${ENVIRONMENT_VARIABLES.USERMANAGEMENT_SERVICE}accounts/${rootGetters['admin/selectedAccount'].accountId}`
        + '/json'
    
    },
    mutations: { 
        [SET_BACKEND_ACCOUNT_ID]: (state, backendAccountId) => state.id = backendAccountId,
        [SET_ACCOUNT_NAME]: (state, name) => state.name = name,
        [SET_ACCOUNT_BUSINESS_UNIT]: (state, businessUnit) => state.businessUnit = businessUnit,
        [SET_ACTIVE_USERS]: (state, activeCount) => state.activeUsers = activeCount,
        [SET_DISABLED_USERS]: (state, disabledCount) => state.disabledUsers = disabledCount,
        [SET_SEAT_COUNT]: (state, seatCount) => state.seatCount = seatCount
    },
    actions: {  
        async getAccount({ commit, getters }) {
            const accountUrl = getters.getAccountUrl;
            const token = Cookies.get('authToken');
            await(get(accountUrl, token)).then((account) => {
                commit(SET_BACKEND_ACCOUNT_ID, account.accountId);
                commit(SET_ACCOUNT_NAME, account.name);
                commit(SET_ACCOUNT_BUSINESS_UNIT, account.businessUnit);
                commit(SET_SEAT_COUNT, account.seatCount);
                commit(SET_ACTIVE_USERS, account.userStatsInfo.activeUsersCount);
                commit(SET_DISABLED_USERS, account.userStatsInfo.disabledUserCount);
            });                
        },

        setUserStatuses({ commit }, statuses) {
            commit(SET_ACTIVE_USERS, statuses.activeUsers);
            commit(SET_DISABLED_USERS, statuses.disabledUsers);
        },

        clearAccountStore({commit}) {
            commit(SET_BACKEND_ACCOUNT_ID, 0);
            commit(SET_ACCOUNT_NAME, '');
            commit(SET_ACCOUNT_BUSINESS_UNIT, '');
            commit(SET_ACTIVE_USERS, 0);
            commit(SET_DISABLED_USERS, 0);
            commit(SET_SEAT_COUNT, 0);   
        }
    }
};