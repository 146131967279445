import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import { admin } from './modules/admin.store';
import { account } from './modules/account.store';
import { users } from './modules/users.store';
import { supportTag } from './modules/supportTag.store';
import { RESPONSE_STATUSES, ERROR_MESSAGE, COOKIE_CONFIGURATIONS } from '@/components/shared/constants';
import Cookies from 'js-cookie';
import { session} from './modules/session.store';

Vue.use(Vuex);

/*TODO: Move authorization code: authToken, loggedIn, sessionStartTime to the session module.
Move the modal code to its own module. 
Ultimately, make sure that the store is made up modules. */
const state = {
    /*TODO: Move authToken getter to its own file 
    so that it can be easily changed once we figure out how to get the token from MSAL.*/    
    authToken: Cookies.get('authToken') || '',   
    modal: {
        isVisible: false,
        type: '',
        message: 'You have set 1 user to disabled',
        title: 'Success',
        isFooter: false,
        isSupportTag: false
    },
    error: false,
    errorTitle: ERROR_MESSAGE.DEFAULT.title,
    errorBody: ERROR_MESSAGE.DEFAULT.body,
    loggedIn: false
};

const getters = {
    authToken: () => Cookies.get('authToken'),
    isAuthenticated: () => state.isAuthenticated,   
    isModalVisible: state => state.modal.isVisible,
    modalType: state => state.modal.type,
    modalMessage: state => state.modal.message,
    modalTitle: state => state.modal.title,
    modalFooter: state => state.modal.isFooter,
    modalSupportTag: state => state.modal.isSupportTag,
    error: state => state.error,
    errorTitle: state => state.errorTitle,
    errorBody: state => state.errorBody,
    loggedIn: () => state.loggedIn
};

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_MODAL_MESSAGE = 'SET_MODAL_MESSAGE';
export const SET_MODAL_TITLE = 'SET_MODAL_TITLE';
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';
export const SET_MODAL_FOOTER_VISIBILITY = 'SET_MODAL_FOOTER_VISIBILITY';
export const SET_MODAL_SUPPORT_TAG_VISIBILITY = 'SET_MODAL_SUPPORT_TAG_VISIBILITY';
export const SET_ERROR_STATE = 'SET_ERROR_STATE';
export const SET_ERROR_TITLE = 'SET_ERROR_TITLE';
export const SET_ERROR_BODY = 'SET_ERROR_BODY';
export const SET_FULLY_LOGGED_IN = 'SET_FULLY_LOGGED_IN';

export const mutations = {
    [SET_AUTH_TOKEN]: (state, authToken) => {
        state.authToken = authToken;
        Cookies.set('authToken', authToken, COOKIE_CONFIGURATIONS.GET(Cookies.get('sessionStartTime')));
    },
    [SET_MODAL_MESSAGE]: (state, message) => state.modal.message = message,
    [SET_MODAL_TITLE]: (state, title) => state.modal.title = title,
    [SET_MODAL_VISIBILITY]: (state, visible) => state.modal.isVisible = visible,
    [SET_MODAL_TYPE]: (state, type) => state.modal.type = type,
    [SET_MODAL_FOOTER_VISIBILITY]: (state, visible) => state.modal.isFooter = visible,
    [SET_MODAL_SUPPORT_TAG_VISIBILITY]: (state, visible) => state.modal.isSupportTag = visible,
    [SET_ERROR_STATE]: (state, error) => state.error = error,
    [SET_ERROR_TITLE]: (state, errorTitle) => state.errorTitle = errorTitle,
    [SET_ERROR_BODY]: (state, errorBody) => state.errorBody = errorBody,
    [SET_FULLY_LOGGED_IN]: (state, loggedIn) => {
        state.loggedIn = loggedIn;
        Cookies.set('loggedIn', loggedIn, COOKIE_CONFIGURATIONS.GET(Cookies.get('sessionStartTime')));
    }    
};

export const actions = {
    async loginSuccess({ commit, getters, dispatch }, data) {
        dispatch('supportTag/supportTagLogin');
        commit(SET_AUTH_TOKEN, data.token);
        try {
            await dispatch('admin/getAllManagedAccounts');
            if (getters.error === true && getters.errorTitle == ERROR_MESSAGE.AUTHORIZATION_FAILED.title) {
                commit(SET_AUTH_TOKEN, '');
                Cookies.remove('authToken');
                router.push('/error');
            }
            else {
                router.push('/manage');
                commit(SET_FULLY_LOGGED_IN, true);
                dispatch('users/getUsers');
                dispatch('account/getAccount');
                dispatch('supportTag/getSupportTagInfo');
                dispatch('admin/getAdminIdentifier');
            }
        }
        
        catch(e) {
            handleError(commit, e);
        }
    },
    showModal({ commit }, data){
        commit(SET_MODAL_VISIBILITY, true);
        commit(SET_MODAL_MESSAGE, data.message);
        commit(SET_MODAL_TITLE, data.title);
        commit(SET_MODAL_TYPE, data.type);
        commit(SET_MODAL_FOOTER_VISIBILITY, data.isFooter);
        commit(SET_MODAL_SUPPORT_TAG_VISIBILITY, data.isSupportTag);
    },
    hideModal({ commit }) {
        commit(SET_MODAL_VISIBILITY, false);
        commit(SET_MODAL_MESSAGE, '');
        commit(SET_MODAL_TITLE, '');
        commit(SET_MODAL_TYPE, '');
        commit(SET_MODAL_FOOTER_VISIBILITY, false);
        commit(SET_MODAL_SUPPORT_TAG_VISIBILITY, false);
    },

    logoutUser({ commit, dispatch }){
        Cookies.remove('authToken');
        Cookies.remove('loggedIn');
        Cookies.remove('sessionStartTime');
        Cookies.remove('sessionId'); 
        dispatch('admin/clearAdminStore');
        dispatch('users/clearUserStore');
        dispatch('account/clearAccountStore');
        dispatch('supportTag/clearSupportTag');
        dispatch('session/clearSessionStore');
        commit(SET_MODAL_MESSAGE, '');
        commit(SET_MODAL_TITLE, '');
        commit(SET_MODAL_VISIBILITY, false);
        commit(SET_MODAL_TYPE, 'Success');
        commit(SET_MODAL_FOOTER_VISIBILITY, false);
        commit(SET_MODAL_SUPPORT_TAG_VISIBILITY, false);
        commit(SET_ERROR_TITLE, ERROR_MESSAGE.DEFAULT.title);
        commit(SET_ERROR_BODY, ERROR_MESSAGE.DEFAULT.body);

        if (router.currentRoute.path !== '/login') {
            window.location.assign('/login');
        }
    }
};

export default new Vuex.Store({
    modules: {
        admin, 
        account,
        users,
        supportTag, 
        session
    },
    state,
    getters,
    mutations,
    actions
});

function handleError(commit, e) {
    commit(SET_ERROR_STATE, true);
    commit(SET_ERROR_TITLE, ERROR_MESSAGE.DEFAULT.title);
    commit(SET_ERROR_BODY, ERROR_MESSAGE.DEFAULT.body);
    if (e === RESPONSE_STATUSES.AUTHORIZATION_FAILED) {
        commit(SET_ERROR_TITLE, ERROR_MESSAGE.AUTHORIZATION_FAILED.title);
        commit(SET_ERROR_BODY, ERROR_MESSAGE.AUTHORIZATION_FAILED.body);
    }

    else if (e === RESPONSE_STATUSES.INTERNAL_SERVER) {
        commit(SET_ERROR_TITLE, ERROR_MESSAGE.INTERNAL_SERVER.title);
        commit(SET_ERROR_BODY, ERROR_MESSAGE.INTERNAL_SERVER.body);
    }
    else {
        commit(SET_ERROR_TITLE, ERROR_MESSAGE.DEFAULT.title);
        commit(SET_ERROR_BODY, ERROR_MESSAGE.DEFAULT.body);
    }
    commit(SET_AUTH_TOKEN, '');
    Cookies.remove('authToken');
    router.push('/error');
}
