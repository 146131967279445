<template>
  <div class="userUpdate">
    <console-button
      class="button reEnableButton"
      :button-type="'button'"
      :button-style="'buttonGhost'"
      :button-size="'default'"
      :is-disabled="!reEnableActivated"
      @on-click="onClick(STATUS.ACTIVE)">
      Re-enable
    </console-button>

    <console-button
      class="disableButton"
      :button-type="'button'"
      :button-style="'buttonGhost'"
      :button-size="'default'"
      :is-disabled="!disableActivated"
      @on-click="onClick(STATUS.DISABLED)">
      Disable
    </console-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConsoleButton from '../shared/ConsoleButton.vue';
import { 
    STATUS,
    MODAL_MESSAGE
} from '../shared/constants';

export default {
    components: { ConsoleButton },
    data() {
        return {
            reEnableActivated: false,
            disableActivated: false,
            STATUS
        };
    },
    computed: mapGetters ([
        'users/selectedActiveUsers',
        'users/selectedDisabledUsers',
        'users/selectedUserIds'
    ]),
    mounted() {
        this.$root.$on('selectionChanged', () => this.updateButtons());
    },
    beforeDestroy() {
        this.$root.$off('selectionChanged', () => this.updateButtons());
    },
    methods: {
        ...mapActions([
            'showModal',
            'hideModal'
        ]),
        onClick(status) {
            this.showModal(MODAL_MESSAGE.CONFIRMATION(STATUS.ACTION[status], this['users/selectedUserIds']));
        },
        updateButtons() {
            const active = this['users/selectedActiveUsers'];
            const disabled = this['users/selectedDisabledUsers'];
            if (disabled && !active) {
                this.reEnableActivated = true;
            }
            else if (active && !disabled) {
                this.disableActivated = true;
            }
            else {
                this.reEnableActivated = false;
                this.disableActivated = false;
                if (this['users/selectedUserIds'].length > 0) {
                    this.showModal(MODAL_MESSAGE.MULTIPLE_STATUSES());
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.userUpdate {
    margin-left: auto;
}

.button {
    margin-right: 40px;
}

@media only screen and (max-width : 1123px ) {
    .userUpdate {
        margin-left: 0;
        margin-top: 10px;
    }
}
</style>
