<template>
  <div class="statusOptions">
    <label class="field-choice-label">
      <input
        ref="active"
        v-model="checked"  
        type="checkbox"
        name="active"
        value="active"
        class="field-choice"
        autocomplete="off"
        @click="onChange()">
      <span class="field-choice-text">Active</span>
    </label>

    <label class="field-choice-label">
      <input
        ref="disabled"
        type="checkbox"
        name="disabled"
        value="disabled"
        class="field-choice"
        autocomplete="off"
        @click="onChange()">
      <span class="field-choice-text">Disabled</span>
    </label>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { 
    STATUS, 
    RESPONSE_STATUSES, 
    MODAL_MESSAGE
} from '../shared/constants';

export default {
    data() {
        return {
            checked: true
        };
    },
    beforeDestroy() {
        this.$root.$off('selectionChanged');
    },
    methods: {
        ...mapMutations([
            'users/SET_USER_STATUS',
            'users/SET_USERS',
            'users/SET_SELECTED_USERS'
        ]),
        ...mapActions([
            'users/getFilteredUsers', 
            'logoutUser', 
            'showModal'
        ]),
        async onChange() {
            const isActiveSelected = this.$refs.active.checked;
            const isDisabledSelected = this.$refs.disabled.checked;

            if (!(isActiveSelected || isDisabledSelected)) {
                this.$store.commit('users/SET_USERS', [], { root: true });
                this.$store.commit('users/SET_SELECTED_USERS', [], { root: true });
                this.$root.$emit('selectionChanged');
                return;
            }
				
            if (isActiveSelected && isDisabledSelected) {
                this.$store.commit('users/SET_USER_STATUS', '', { root: true });
            }
            else if (isActiveSelected) {
                this.$store.commit('users/SET_USER_STATUS', STATUS.ACTIVE, { root: true });
            }
            else {
                this.$store.commit('users/SET_USER_STATUS', STATUS.DISABLED, { root: true });
            }

            this.getUsersWithFilters();
           
        },

        async getUsersWithFilters() {
            try {
                await this['users/getFilteredUsers']();
                this.$root.$emit('selectionChanged');
            }
            catch(error) {
                if (
                    error == RESPONSE_STATUSES.AUTHENTICATION_FAILED ||
                      error == RESPONSE_STATUSES.AUTHORIZATION_FAILED
                ) {
                    this.logoutUser();
                }
                else {
                    this.showModal(MODAL_MESSAGE.ERROR('Filtering by status'));
                }
            }
        }
    }
};
</script>

<style lang="scss">
.field-choice-label {
  padding-bottom: 4px;
  padding-top: 4px;
  display: inline-flex;
  font-size: 16px;
  line-height: 1.25;
  cursor: default;
  padding-right: 17px;
}

.field-choice {
  cursor: pointer;
  padding: 0;
  height: 16px;
  width: 16px;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  box-sizing: border-box;
    clip: rect(0,0,0,0);
  position: absolute;
}

.field-choice-text::before {
  background-color: $STATUS-FILTER-BOX-BGCLR;
  border: 1px solid $STATUS-FILTER-BOX-BORDER;
    content: '';
  cursor: pointer;
  height: 16px;
  left: 0;
  position: absolute;
  top: calc(50% - 1px);
  transform: translateY(-50%);
  width: 16px;
}

.field-choice[type=checkbox]:enabled:checked+.field-choice-text::before {
  background-color: $STATUS-FILTER-CHECKED-BGCLR;
  border-color: $STATUS-FILTER-CHECKED-BORDER;
  cursor: pointer;
}

.field-choice-text {
  color: $STATUS-FILTER-TEXT;
  margin-left: 0;
  padding-left: 8px;
  position: relative;
  font-size: 14px;
  font-weight: normal;
  height: 18px;
  line-height: 18px;
  width: 46px;
  padding-left: 24px;
}

.field-choice[type=checkbox]:checked+.field-choice-text::after  {
  border: 0 solid currentColor;
  border-left-width: 1px;
  border-bottom-width: 1px;
  color: $WK-WHITE;
    content: '';
  height: 4px;
  left: 8px;
  position: absolute;
  top: calc(50% - 1px);
  transform: rotate(-45deg) translateY(-50%) translateY(-3px);
  width: 8px;
  cursor: pointer;
}
</style>