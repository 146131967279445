import { b2cPolicies } from './policies.js';
import { apiConfig } from './apiConfig';
import * as Msal from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md 
 */
export const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_CLIENT_ID, 
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [ b2cPolicies.authorityDomain ], // You must identify your tenant's domain as a known authority.
        redirectUri: '/login' // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                case Msal.LogLevel.Error:
                    console.error(message);
                    return;
                case Msal.LogLevel.Info:
                    console.info(message);
                    return;
                case Msal.LogLevel.Verbose:
                    console.debug(message);
                    return;
                case Msal.LogLevel.Warning:
                    console.warn(message);
                    return;
                }
            }
        }
    }
};
  
/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [ 'openid', ...apiConfig.b2cScopes ]
};