<template>
  <div class="login">
    <SupportTag />
    <form
      class="loginForm"
      @submit.prevent="validateEmail">
      <div class="formTitle">Log In</div>
      <div class="userEmail">
        <console-notification
          v-if="error"
          class="emailError"
          :notification-mode="'error'">
          <template #title> Invalid Email </template>
        </console-notification>
        <label class="fieldLabel">Email</label>
        <div class="fieldBody">
          <input
            v-model.trim="email"
            type="text"
            placeholder="example@domain.com"
            class="fieldInput"
            autocomplete="off"
            autocapitalize="off"
            autocorrect="off"
            maxlength="50"
            required>
        </div>
      </div>
      <console-button
        :button-type="'submit'"
        :button-style="'auto'">Log In</console-button>
    </form>
  </div>
</template>

<script>
import ConsoleButton from './shared/ConsoleButton.vue';
import ConsoleNotification from './shared/ConsoleNotification.vue';
import SupportTag from './shared/SupportTag.vue';
import { signIn } from './B2C/authPopup.js';
import { EMAIL_REGEX, XSS_INJECTION_PATTERN } from './shared/constants';

export default {
    components: { ConsoleButton, ConsoleNotification, SupportTag },
    data() {
        return {
            authToken: '',
            email: '',
            error: false
        };
    },

    methods: {
        validateEmail() {
            EMAIL_REGEX.test(this.email) && !XSS_INJECTION_PATTERN.test(this.email) ? this.triggerLoginPopup() : this.error = true;
        },
        triggerLoginPopup() {
            signIn(this.email);
        }
    }
};
</script>

<style lang="scss" scoped>
.login {
   padding-bottom: 144px;
}

.loginForm {
    width: 376px;
    background: $LOGIN-FORM-BG;
    padding: 42px 48px 32px 48px;
    margin: 120px auto auto auto;
    font-size: 14px;
    line-height: 18px;
}

.fieldInput {
    border: 1px solid $LOGIN-INPUT-BORDER;
    font: inherit;
    line-height: 18px;
    padding: 7px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::placeholder {
        color: $LOGIN-INPUT-PLACEHOLDER;
        font-weight: 300;
    }
}

.userEmail {
    margin: 32px auto 24px auto;
    color: $LOGIN-INPUT-NAME;
}

.emailError {
    margin-bottom: 18px;
}

.fieldLabel {
    margin-bottom: 4px;
    font-weight: 500;
    display: block;
}

.fieldBody {
    display: flex;
    height: 32px;
}

.formTitle {
    font-weight: bold;
    color: $LOGIN-TITLE;
    font-size: 21px;
}
</style>