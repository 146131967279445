<template>
  <div class="userFilter">
    <div class="statusFilter">
      <div class="userFilterInstructions">Show Users By Status</div>
      <status-filter />
    </div>
    <form @submit.prevent="onSubmit">
      <div class="userFilterInstructions">Filter By Name or Email</div>
      <div class="userChoices">
        <select
          v-model="category"
          @change="clearInput()">
          <option value="name">Name</option>
          <option value="email">Email</option>
        </select>
        <div v-show="category === 'name'">
          <div class="userInput">
            <console-input
              v-model="firstName"
              class="nameInput"
              :placeholder="'First Name'" />
            <console-input
              v-model="lastName"
              class="nameInput"
              :placeholder="'Last Name'" />
            <console-button
              :button-type="'submit'"
              :button-style="'auto'"
              class="nameSearchButton"
            ><span class="wk-icon-search"
            /></console-button>
          </div>
        </div>
        <div v-show="category === 'email'">
          <div class="userInput">
            <console-input
              v-model="email"
              class="emailInput"
              :placeholder="'Email'" />
            <console-button
              :button-type="'submit'"
              :button-style="'auto'"
              class="emailSearchButton"
            ><span class="wk-icon-search"
            /></console-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
    RESPONSE_STATUSES,
    MODAL_MESSAGE
} from '../shared/constants';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import ConsoleInput from '../shared/ConsoleInput.vue';
import ConsoleButton from '../shared/ConsoleButton.vue';
import StatusFilter from './StatusFilter.vue';

export default {
    components: { ConsoleInput, ConsoleButton, StatusFilter },
    data() {
        return {
            category: 'name',
            firstName: '',
            lastName: '',
            email: ''
        };
    },

    computed: mapGetters ([
        'authToken'
    ]),

    beforeDestroy() {
        this.$root.$off('selectionChanged');
    },  

    methods: {
        ...mapMutations([
            'users/SET_FIRSTNAME_SEARCH',
            'users/SET_LASTNAME_SEARCH',
            'users/SET_EMAIL_SEARCH'
        ]),

        ...mapActions([
            'users/getFilteredUsers', 
            'showModal', 
            'logoutUser'
        ]),

        clearInput() {
            this.firstName = '';
            this.lastName = '';
            this.email = '';
        },


        async onSubmit() {
            this.$store.commit('users/SET_FIRSTNAME_SEARCH', '', { root: true });
            this.$store.commit('users/SET_LASTNAME_SEARCH', '', { root: true });
            this.$store.commit('users/SET_EMAIL_SEARCH', '', { root: true });
            let validSearch = true;  
            if (this.category === 'name') {
                validSearch = validSearch && this.validateSearch(('users/SET_FIRSTNAME_SEARCH'), this.firstName);
                validSearch = validSearch && this.validateSearch(('users/SET_LASTNAME_SEARCH'), this.lastName);
            }
            else {
                validSearch = validSearch && this.validateSearch(('users/SET_EMAIL_SEARCH'), this.email);
            }

            if (!validSearch) {
                return;
            }

            await this.getUsersWithFilters();   
        },

        async getUsersWithFilters() {
            try {
                await this['users/getFilteredUsers']();
                this.$root.$emit('selectionChanged');
            }
            catch (error) {
                if (error === RESPONSE_STATUSES.AUTHENTICATION_FAILED || error === RESPONSE_STATUSES.AUTHORIZATION_FAILED) {
                    this.logoutUser();
                }
                else {
                    this.showModal(MODAL_MESSAGE.ERROR('Search action'));
                }
            }
        },

        validateSearch(action, input) {
            const xssInjectionPattern = /<\/?[^>]*(img|script|href|on[^>\s]+|src|iframe|input|background|dynsrc|lowsrc|style|size|meta|url)[^>]*>/ig;
			
            const xssMatch = xssInjectionPattern.test(input);
            if (!xssMatch) {
                this.$store.commit(action, input, { root: true });
                return true;
            }

            this.showModal(MODAL_MESSAGE.INVALID_SEARCH_INPUT());
            return false;
        }
    }
};
</script>

<style lang="scss">
.userChoices {
  display: flex;
  flex-wrap: wrap;
  select, Input, button {
    height: 32px;
    font-size: 14px;
    box-sizing: content-box;
    padding-top: 0;
    padding-bottom: 0;   
  }

  select, Input {
    width: 196px;
    border: 1px solid $WK-GRAY-TINT3;
    background-color: $WK-WHITE;
    padding-left: 8px;
    padding-right: 2px;
  }

  button {
    width: 32px;
    padding: 0;
  }

  .emailInput {
    width: 391px;
    
    @media only screen and (max-width: 600px) {
      width: 196px;
    }
  }
}

.userFilterInstructions {
  font-family: "Noto Sans Light", sans-serif;
  margin-bottom: 5px;
}

.userInput {
  display: flex;
  flex-wrap: wrap;
}

.wk-icon-search {
  border-radius: 0;
}

.statusFilter {
  padding-bottom: 17px;
}
</style>