<template>
  <div
    class="notification"
    v-bind="$attrs"
    :class="notificationClass">
    <span
      class="notificationIcon"
      :class="notificationIconClass" />
    <p class="notificationTitle"><slot name="title" /></p>
    <p class="notificationContent"><slot /></p>
    <div
      v-if="hasFooterSlot"
      class="notificationFooter">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>

export default {
    props: {
        notificationMode: {
            type: String,
            default: 'info'
        }
    },
    computed: {
        notificationClass() {
            return `notification-${this.notificationMode}`;
        },
        notificationIconClass() {
            let icon = 'filled-info';
            if (this.notificationMode === 'error') {
                icon = 'filled-stop-sign';
            }
            else if (this.notificationMode === 'success') {
                icon = 'filled-check-circle';
            }
            else if (this.notificationMode === 'warn') {
                icon = 'filled-caution';
            }
            else if (this.notificationMode === 'basic') {
                return;
            }
            else if (this.notificationMode === 'support') {
                return;
            }
            return `wk-icon-${icon}`;
        },
        hasFooterSlot() {
            const ss = this.$scopedSlots;
            const footerNodes = ss && ss.footer && ss.footer();
            return footerNodes && footerNodes.length;
        }
    }
};
</script>

<style scoped lang="scss">
/* Refer this link for notification DXG component styles:
	https://jumpstart.wolterskluwer.io/?path=/docs/components-notification-html-with-classes--inline-default
*/

.notification {
	border-color: $MODAL-BORDER-DEFAULT;
	border-style: solid;
	border-width: 1px 1px 1px 4px;
	margin-top: 24px;
	padding: 16px 16px 16px 52px;
	background: $WK-WHITE;
	position: relative;

	.notificationIcon {
		left: 16px;
		margin-left: 0;
		margin-right: 0;
		top: 16px;
		font-size: 16px;
		position: absolute;
	}

	.notificationTitle {
		color: $MODAL-TEXT-TITLE-COL;
		font-size: 14px;
		line-height: 1.28571;
		margin-bottom: 4px;
		text-align: left;
		margin-top: 0;
		padding: 0;
		font-weight: bold;
	}

	.notificationContent {
		margin: 0;
		padding: 0;
		color: $MODAL-TEXT-BODY-COL;
		font-size: 14px;
		line-height: 1.5;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-align: left;
	}
}

.notification- {
	&info {
		border-color: $MODAL-INFO;
	}

	&error {
		border-color: $MODAL-ERROR;
		color: $MODAL-ERROR;
	}

	&success {
		border-color: $MODAL-SUCCESS;
	}

	&warn {
		border-color: $MODAL-WARN;
	}

	&basic {
		padding-left: 16px;
	}

	&support {
		padding-left: 32px;
		padding-right: 32px;
		align-items: center;
		display: flex;
		flex-direction: column;
		.notificationTitle {
			margin-bottom: 20px;
		}
	}
}
</style>
