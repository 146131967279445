<template>
  <div class="user-table"> 
    <ag-grid-vue 
      class="userTable wk-advanced-table wk-is-striped"
      :column-defs="columnDefs"
      :row-data="rowData"
      :grid-options="gridOptions"
      :enable-col-resize="true"
      :pagination="pagination"
      :pagination-page-size="this['users/paginationPageSize']" />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import {
    SET_ERROR_STATE,
    SET_AUTH_TOKEN,
    SET_FULLY_LOGGED_IN
} from '../../store/manage.store';
import { 
    SORT_BY,
    RESPONSE_STATUSES,
    MODAL_MESSAGE
} from '../shared/constants';
const defaultCellRenderer = function(params){
    return params.value;
};

export default {
    components: {
        AgGridVue
    },

    props: {
        rowData: Array
    },

    data() {
        return {
            gridOptions: null,
            gridApi: null,
            columnApi: null,
            columnDefs: null
        };
    },

    computed: {
        ...mapGetters([
            'users/selectedUsers',
            'users/users',
            'users/sortOrder',
            'users/sortBy',
            'users/paginationPageSize'
        ])
    },
    
    beforeMount() {
        this.gridOptions = {
            rowSelection: 'multiple',
            animateRows: true,
            suppressDragLeaveHidesColumns: true,
            enableCellTextSelection: true,
            suppressRowClickSelection: true,
            rowHeight: 40,
            headerHeight: 48,
            icons : {
                sortAscending: '<span class="wk-icon-arrow-up" aria-hidden="true"/></span>',
                sortDescending: '<span class="wk-icon-arrow-down" aria-hidden="true"/></span>'
            },
            onSelectionChanged: this.onSelectionChanged,
            suppressColumnVirtualisation: true
        };  
        this.columnDefs = [
            { headerName: 'First Name', field: 'firstName', pinned: 'left', lockPosition: 'left', filter: false, sortable: false, resizable:true, checkboxSelection: true, headerCheckboxSelection: true, cellRenderer: defaultCellRenderer},
            { headerName: 'Last Name', field: 'lastName', pinned: 'left', lockPosition: 'left', filter: false, sortable: false, resizable:true, cellRenderer: defaultCellRenderer },
            { headerName: 'Email', field: 'email', filter: false, lockPinned:true, sortable: false, resizable:true, cellRenderer: defaultCellRenderer },
            { headerName: 'Status', field: 'status', filter: false, lockPinned:true, sortable: false, resizable:true, cellRenderer: defaultCellRenderer },
            { headerName: 'Start Date', field: 'startDate', filter: false, lockPinned:true, sortable: false, resizable:true, cellRenderer: defaultCellRenderer },
            { headerName: 'End Date', field: 'endDate', filter: false, lockPinned:true, sortable: false, resizable:true, cellRenderer: defaultCellRenderer}
        ];
        this.gridApi = null;
        this.columnApi = null;
        this.pagination = true;
    },

    mounted() {
        this.$root.$on('updatedUserStatus', () => this.clearSelection());

        if(this.gridOptions.api) {
            this.initiateSelectors();
            this.gridApi.sizeColumnsToFit();
            this.$nextTick(() => {
                this.sortingListener();
                this.addChildListeners();
            });
        }
    },

    beforeDestroy() {
        this.$root.$off('updatedUserStatus', () => this.clearSelection());
        this.$root.$off('refreshTable');
        this.$root.$off('selectionChanged');
        this.gridOptions.api.destroy();
        this.sortingListener(false);
    },
  
    methods: {
        ...mapMutations([
            SET_ERROR_STATE,
            SET_AUTH_TOKEN,
            SET_FULLY_LOGGED_IN,
            'users/SET_SELECTED_USERS',
            'users/SET_SORT_BY',
            'users/SET_SORT_ORDER'
        ]),
        ...mapActions([
            'users/getFilteredUsers', 
            'logoutUser',
            'showModal'
        ]),
        getSelectedRows() {
            const selectedNodes = this.gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            this.$store.commit('users/SET_SELECTED_USERS', selectedData, { root: true });
        },
        onSelectionChanged() {
            this.getSelectedRows();
            this.$root.$emit('selectionChanged');
        },
        clearSelection() {
            this.gridApi.deselectAll();
        },
        async sortByColumn(event, columnId) {
            let sort = '';
            let colName = SORT_BY[columnId];

            const element = document.querySelector(`[col-id="${columnId}"]`);

            if (this['users/sortBy'] !== colName || this['users/sortOrder'] === '') {
                sort = 'asc';
            }
            else if (this['users/sortOrder'] === 'asc') {
                sort = 'desc';
            }
            else {
                sort = '';
                colName = '';
            } 
            this.$store.commit('users/SET_SORT_BY', colName, { root: true });
            this.$store.commit('users/SET_SORT_ORDER', sort, { root: true });
            try {
                await this['users/getFilteredUsers']()
                    .then(() => {
                        this.clearAllArrows();
                        if (sort === 'asc') {
                            this.updateArrowDisplay(element, true, false);
                        }
                        else if (sort === 'desc') {
                            this.updateArrowDisplay(element, false, true);
                        }
                        else {
                            this.updateArrowDisplay(element);
                        }
                        if (this.error) {
                            this[SET_ERROR_STATE](false);
                            this.$router.push('/manage');
                        }
                        this.gridOptions.api.setRowData(this['users/users']);
                    });
            }
            catch(error) {
                this[SET_ERROR_STATE](true);
				
                if (
                    error === RESPONSE_STATUSES.AUTHENTICATION_FAILED ||
					error === RESPONSE_STATUSES.AUTHORIZATION_FAILED
                ) {
                    this.logoutUser(); 
                }
                else {
                    this.showModal(MODAL_MESSAGE.ERROR('Sort action'));
                }
            }
        },
        initiateSelectors() {
            this.gridApi = this.gridOptions.api;
            this.gridColumnApi = this.gridOptions.columnApi;
            this.columnState = this.gridColumnApi.getColumnState();

            this.descElements = document.querySelectorAll('[ref="eSortDesc"]');
            this.ascElements = document.querySelectorAll('[ref="eSortAsc"]');
            this.inputElement = document.querySelector('[ref="eInput"]');
            this.resizeElements = document.querySelectorAll('[ref="eResize"]');
        },
        updateArrowDisplay(element, asc = false, desc = false) {
            const descElement = element.querySelector('[ref="eSortDesc"]');
            const ascElement = element.querySelector('[ref="eSortAsc"]');

            if (asc) {
                ascElement.classList.remove('ag-hidden');
                descElement.classList.add('ag-hidden');

            }
            else if (desc) {
                descElement.classList.remove('ag-hidden');
                ascElement.classList.add('ag-hidden');
            }
            else {
                ascElement.classList.add('ag-hidden');
                descElement.classList.add('ag-hidden');
            } 
        },
        clearAllArrows() {
            this.columnState.forEach(column => {
                const ele = document.querySelector(`[col-id="${column.colId}"]`);
                this.updateArrowDisplay(ele);
            });
        },
        addChildListeners() {
            this.inputElement.addEventListener('click', e => e.stopPropagation());
		
            this.descElements.forEach(ele => {
                const descIcon = document.createElement('SPAN');
                descIcon.classList.add('wk-icon-arrow-down');
                ele.appendChild(descIcon);
            });

            this.ascElements.forEach(ele => {
                const ascIcon = document.createElement('SPAN');
                ascIcon.classList.add('wk-icon-arrow-up');
                ele.appendChild(ascIcon);
            });

            this.resizeElements.forEach(ele => {
                ele.addEventListener('click', e => e.stopPropagation());
            });
        },
        removeChildListeners() {
            this.inputElement.removeEventListener('click', e => e.stopPropagation());

            this.resizeElements.forEach(ele => {
                ele.removeEventListener('click', e => e.stopPropagation());
            });
        },
        sortingListener(add = true) {
            this.columnState.forEach(column => {
                const columnId = column.colId;
                const columnEle = document.querySelector(`[col-id="${columnId}"]`);

                if (add) {
                    columnEle.addEventListener('click', e => this.sortByColumn(e, columnId));
                }
                else {
                    if (columnEle !== null) {
                        columnEle.removeEventListener('click', e => this.sortByColumn(e, columnId));
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss">
.userTable {
    height: 514px;
    .ag-cell-focus, .ag-cell {
        border: none;
    }
	&.wk-advanced-table {
		.ag-ltr .ag-header-select-all {
			margin-right: 8px;
		}
		.ag-cell {
			line-height: normal;
		}
		.ag-checkbox-input-wrapper input {
			cursor: pointer;
		}
		.ag-row-selected.ag-row-hover {
			background-color: var(--ag-row-hover-color, $TABLE-ROW-EVEN-SELECTED-HOVER);
		}
		&.wk-is-striped .ag-row-odd {
			&.ag-row-hover {
				background-color: var(--ag-row-hover-color, $TABLE-ROW-ODD-HOVER);
			}
			&.ag-row-selected {
				background-color: var(--ag-selected-row-background-color, $TABLE-ROW-ODD-SELECTED);
				&.ag-row-hover {
					background-color: var(--ag-row-hover-color, $TABLE-ROW-ODD-SELECTED-HOVER);
				}
			}
		}
	}
}
</style>
