import Vue from 'vue';
import VueRouter from 'vue-router';
import ConsoleError from './components/ConsoleError.vue';
import AdminLogin from './components/AdminLogin.vue';
import UserManage from './components/UserManagement/UserManage.vue';
import { TAB_TITLES } from './components/shared/constants';
import Cookies from 'js-cookie';

Vue.use(VueRouter);

const routes = [
    { 
        path: '/',
        redirect: '/manage'
    },
    { 
        path: '/error',
        component: ConsoleError,
        name: 'error',
        meta: {title: TAB_TITLES.errorTitle}
    },
    { 
        path: '/login',
        component: AdminLogin,
        name: 'login'
    },
    { path: '/manage',
        component: UserManage,
        name: 'manage',
        meta: {requiresAuth: true}
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !Cookies.get('authToken')) {
        next('login');
    }
    else {
        next();
    }
});

router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta.title || document.title;
    });
});

export default router;