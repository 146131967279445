export class Account {
    accountid;
    name;
    businessUnit;

    constructor(accountid, name, businessUnit) {
        this.accountid = accountid;
        this.name = name;
        this.businessUnit = businessUnit;
    }

    get getAccountId() {
        return this.accountid;
    }

    get getAccountName() {
        return this.name;
    }

    get getBusinessUnit() {
        return this.businessUnit;
    }
}