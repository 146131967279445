export const b2cPolicies = {
    names: {
        signUpSignIn: 'b2c_1a_wkce_signup_signin'
    },
    authorities: {
        signUpSignIn: {
            authority: `${process.env.VUE_APP_SIGNIN_AUTHORITY}/b2c_1a_wkce_signup_signin`
        }
    },
    authorityDomain: process.env.VUE_APP_AUTHORITY_DOMAIN
};