<template>
  <button
    :type="buttonType"
    :class="buttonClass"
    :disabled="isDisabled"
    @click="$emit('on-click')">
    <slot>Submit</slot>
  </button>
</template>

<script>

export default {
    props: {
        buttonType: {
            type: String,
            default: 'submit'
        },
        buttonStyle: {
            type: String,
            default: ''
        },
        buttonSize: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonClass() {
            return `wkButton ${this.buttonStyle} ${this.buttonSize}`;
        }
    }
};
</script>

<style lang="scss">
.wkButton {
    font-family: inherit;
    transition: color 300ms cubic-bezier(.4,0,.2,1);
    transition-delay: 0ms;
    transition-property: color,background-color,border;
    appearance: none;
    background-color: $WK-BLUE;
    border: 1px solid transparent;
    border-radius: 0;
    color: $WK-WHITE;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 7px;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    touch-action: manipulation;
    vertical-align: middle;
    
    &:hover {
        background-color: $WK-BLUE-SHADE2;
        color: $WK-WHITE;
        cursor: pointer;
    }
}

.buttonGhost {
    background-color: transparent;
    border-color: $WK-BLUE-SHADE1;
    color: $WK-BLUE-SHADE1;

    &:disabled {
        opacity: .5;
        pointer-events: none;
    }
}

.buttonText {
    background-color: transparent;
    color: $WK-BLUE-SHADE1;
    &:hover {
        background-color: transparent;
        color: $WK-BLUE-SHADE2;
        text-decoration: underline;
        cursor: pointer;
    }
}

.auto {
    width: 100%;
}

.default {
    width: 102px;
}
</style>