import Cookies from 'js-cookie';
import { get } from '@/api/rest.utility';
import { COOKIE_CONFIGURATIONS, URLS } from '@/components/shared/constants';

export const SET_SUPPORT_TAG_SERVER = 'SET_SUPPORT_TAG_SERVER';
export const SET_SUPPORT_TAG_IP = 'SET_SUPPORT_TAG_IP';
export const SET_SUPPORT_TAG_ACCESS_TYPE = 'SET_SUPPORT_TAG_ACCESS_TYPE';
export const SET_SUPPORT_TAG_UTD_ID = 'SET_SUPPORT_TAG_UTD_ID';
export const SET_SUPPORT_TAG_TECHNICAL_INFO = 'SET_SUPPORT_TAG_TECHNICAL_INFO';
export const SET_SUPPORT_TAG_BACKEND_READ_VERSION = 'SET_SUPPORT_TAG_BACKEND_READ_VERSION';
export const SET_SUPPORT_TAG_BACKEND_WRITE_VERSION = 'SET_SUPPORT_TAG_BACKEND_WRITE_VERSION';
export const SET_SUPPORT_TAG_CLIENT = 'SET_SUPPORT_TAG_CLIENT';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_SESSION_START_TIME = 'SET_SESSION_START_TIME';

export const supportTag = {
    namespaced: true,
    state: () => ({
        supportTag: {
            server: '',
            ip: '',
            accessType: '',
            technicalInfo: '',
            backendReadVersion: '0.0.0',
            backendWriteVersion: '0.0.0',
            clientVersion: '0.0.0'
        }
    }),
    getters: {
        sessionId: () => Cookies.get('sessionId'),
        sessionStartTime: () => Cookies.get('sessionStartTime'),
        supportTag: state => state.supportTag
    },
    mutations: {
        [SET_SUPPORT_TAG_SERVER]: (state, server) => state.supportTag.server = server,
        [SET_SUPPORT_TAG_IP]: (state, ip) => state.supportTag.ip = ip,
        [SET_SUPPORT_TAG_ACCESS_TYPE]: (state, accessType) => state.supportTag.accessType = accessType,
        [SET_SUPPORT_TAG_UTD_ID]: (state, utdId) => state.supportTag.utdId = utdId,
        [SET_SUPPORT_TAG_TECHNICAL_INFO]: (state, technicalInfo) => state.supportTag.technicalInfo = technicalInfo,
        [SET_SUPPORT_TAG_BACKEND_READ_VERSION]: (state, backendReadVersion) => state.supportTag.backendReadVersion = backendReadVersion,
        [SET_SUPPORT_TAG_BACKEND_WRITE_VERSION]: (state, backendWriteVersion) => state.supportTag.backendWriteVersion = backendWriteVersion,
        [SET_SUPPORT_TAG_CLIENT]: (state, clientVersion) => state.supportTag.clientVersion = clientVersion,
        [SET_SESSION_ID]: (state, sessionId) => {
            Cookies.set('sessionId', sessionId, COOKIE_CONFIGURATIONS.GET(state.sessionStartTime));
        },
        [SET_SESSION_START_TIME]: (state, sessionStartTime) => {
            Cookies.set('sessionStartTime', sessionStartTime, COOKIE_CONFIGURATIONS.GET(state.sessionStartTime));
        }
    },
    actions: {
        async getClientVersion({ commit }) {
            const token = Cookies.get('authToken');
            try {
                const response = await get(URLS.INFO_URL, token);
                commit(SET_SUPPORT_TAG_CLIENT, response.app.version);
            }
            catch (e) {
                const clientVersion = require('../../../package.json').version;
                commit(SET_SUPPORT_TAG_CLIENT, clientVersion);
            }           
        },
    
        async getSupportTagInfo({ commit, getters }) {
            const token = Cookies.get('authToken');
            const supportTag = await get(URLS.SUPPORT_TAG, token);
            if (typeof getters.sessionId === 'undefined') {
                const sessionId = supportTag.sessionId.substring(0, 10);
                commit(SET_SESSION_ID, sessionId);
            }
            commit(SET_SUPPORT_TAG_BACKEND_READ_VERSION, supportTag.webfarmServerVersion);
            commit(SET_SUPPORT_TAG_BACKEND_WRITE_VERSION, supportTag.centralServerVersion);
            commit(SET_SUPPORT_TAG_SERVER, supportTag.webfarmName);
    
            if (process.env.VUE_APP_REGION !== 'production') {
                const databaseConnection = supportTag.databaseConnection;
                const technicalInfo = databaseConnection.slice(databaseConnection.lastIndexOf('@') + 1);
                commit(SET_SUPPORT_TAG_TECHNICAL_INFO, technicalInfo);
            }
        },
        supportTagLogin({ commit }) {
            commit(SET_SESSION_START_TIME, Date.now());
            commit(SET_SUPPORT_TAG_ACCESS_TYPE, 'OI');
        },
        clearSupportTag({ commit }) {
            commit(SET_SUPPORT_TAG_SERVER, ''),
            commit(SET_SUPPORT_TAG_IP, ''),
            commit(SET_SUPPORT_TAG_ACCESS_TYPE, '');
            commit(SET_SUPPORT_TAG_UTD_ID, '');
            commit(SET_SUPPORT_TAG_TECHNICAL_INFO, '');
            commit(SET_SUPPORT_TAG_BACKEND_READ_VERSION, '0.0.0');
            commit(SET_SUPPORT_TAG_BACKEND_WRITE_VERSION, '0.0.0');
            commit(SET_SESSION_START_TIME, '');
        }
    }
};