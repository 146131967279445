<template>
  <div class="userManage">
    <console-modal
      v-if="isModalVisible && !modalSupportTag" 
      :dialog-type="modalType"
      :hide-header-close="hideHeaderClose"
    >
      <template #header>{{ modalTitle }}</template>
      <template
        v-if="modalFooter"
        #footer>
        <console-button
          :button-type="'button'"
          class="actionButton"
          :button-style="'buttonText'"
          @on-click="updateTable(currentStatus)">{{ STATUS.ACTION[currentStatus] }}</console-button>
        <console-button
          :button-type="'button'"
          class="cancelButton"
          :button-style="'buttonText'"
          @on-click="closeModal()">Cancel</console-button>
      </template>
      {{ modalMessage }}
    </console-modal>
    <SupportTag />
    <div class="tableToolbar">
      <user-filter />
      <user-update />
    </div>
    <user-table :row-data="this['users/users']" />
    <SeatCount />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import UserTable from './UserTable.vue';
import UserUpdate from './UserUpdate.vue';
import UserFilter from './UserFilter.vue';
import ConsoleModal from '../shared/ConsoleModal.vue';
import ConsoleButton from '../shared/ConsoleButton.vue';
import SupportTag from '../shared/SupportTag.vue';
import SeatCount from './SeatCount.vue';
import { 
    STATUS, 
    RESPONSE_STATUSES, 
    ERROR_MESSAGE, 
    USER_UPDATE,
    MODAL_MESSAGE
} from '../shared/constants';
import {
    SET_ERROR_STATE,
    SET_AUTH_TOKEN,
    SET_FULLY_LOGGED_IN,
    SET_ERROR_TITLE,
    SET_ERROR_BODY  
} from '../../store/manage.store';

export default {
    components: {
        UserUpdate,
        UserFilter,
        UserTable,
        ConsoleModal,
        ConsoleButton,
        SupportTag,
        SeatCount
    },
    data() {
        return {
            STATUS,
            hideHeaderClose: false
        };
    },
    computed: {
        ...mapGetters([
            'users/users',
            'isModalVisible',
            'modalMessage',
            'modalTitle',
            'modalFooter',
            'modalType',
            'users/selectedActiveUsers',
            'users/selectedUserIds',
            'error',
            'modalSupportTag', 
            'session/sessionExpired'
        ]),
        ...mapGetters('admin', [
            'selectedAccountId'
        ]),
        ...mapGetters('account', [
            'seatCount',
            'activeUsers'
        ]),
        activeAccount() {
            return this.selectedAccountId;
        },
        currentStatus() {
            return this['users/selectedActiveUsers'] ? 'Disabled' : 'Active';
        }, 
        shouldLogOutUser() {
            return this['session/sessionExpired'];
        }
    },

    watch: {
        activeAccount() {
            this.hideModal();
            this[SET_ERROR_TITLE](ERROR_MESSAGE.DEFAULT.title);
            this[SET_ERROR_BODY](ERROR_MESSAGE.DEFAULT.body);
            try {
                this.getAccount();
                this['users/getUsers']();
                this.getAdminIdentifier();
                this.getSupportTagInfo();
            }
            catch(error) {
                if (error === RESPONSE_STATUSES.AUTHENTICATION_FAILED ||
                    error === RESPONSE_STATUSES.AUTHORIZATION_FAILED) {
                    this.logoutUser();
                }
                else {
                    this.showModal(MODAL_MESSAGE.ERROR('Action'));
                }
            }
        }, 
        shouldLogOutUser(newValue) {
            if (newValue) {
                this.logoutUser();
            }
        }
    },

    beforeDestroy() {
        this.$store.commit('users/SET_SELECTED_USERS', '', { root: true });
        this.$root.$off('updatedUserStatus');
    },

    beforeMount() {
        this.getAllManagedAccounts();
    },
    
    methods: {
        ...mapActions([
            'users/getUsers',
            'hideModal',
            'users/updateUsers',
            'showModal',
            'users/getFilteredUsers', 
            'logoutUser',
            'loggedIn'
        ]),
        ...mapActions('supportTag', [
            'getSupportTagInfo'
        ]),
        ...mapActions('admin', [
            'getAllManagedAccounts',
            'getAdminIdentifier'
        ]),
        ...mapActions('account', [
            'getAccount'
        ]),
        ...mapMutations( 
            [
                'users/SET_SELECTED_USERS',
                SET_ERROR_STATE,
                SET_AUTH_TOKEN,
                SET_FULLY_LOGGED_IN,
                SET_ERROR_TITLE,
                SET_ERROR_BODY
            ]),
        closeModal() {
            this.hideModal();
        },
        async updateTable(status) {
            this.hideModal();
            await this['users/updateUsers']({status})
                .then(() => {
                    this.hideHeaderClose = true;
                    this.showModal(MODAL_MESSAGE.LOADING());

                    let updated = false;
                    let currentUsers = this['users/users'];
                    const numAttempts = USER_UPDATE.ATTEMPTS;
                    const numSelected = this['users/selectedUserIds'].length;

                    for (let i = 0; i < numAttempts; i++) {
                        setTimeout(async() => {
                            if (!updated) {
                                await this['users/getFilteredUsers']();

                                if (JSON.stringify(currentUsers) !== JSON.stringify(this['users/users'])) {
                                    this.$root.$emit('updatedUserStatus', status, numSelected);
                                    this.hideHeaderClose = false;
                                    this.showModal(MODAL_MESSAGE.SUCCESS(numSelected, status));

                                    updated = true;
                                    currentUsers = this['users/users'];
                                }
                                else if (i === numAttempts - 1) {
                                    this.$root.$emit('updatedUserStatus', status, numSelected);
                                    this.hideHeaderClose = false;
                                    this.showModal(MODAL_MESSAGE.SUCCESS_REFRESH(numSelected, status));
                                }
                            }
                            
                        }, USER_UPDATE.TIMEOUT * i);
                    }
                })
                .catch((error) => {
                    if (
                        error === RESPONSE_STATUSES.AUTHENTICATION_FAILED ||
                        error === RESPONSE_STATUSES.AUTHORIZATION_FAILED
                    ) {
                        this.logoutUser();
                    }
                    else if (error === RESPONSE_STATUSES.INTERNAL_SERVER && status.localeCompare('Active') === 0) {
                        this.showModal(MODAL_MESSAGE.SEATS_EXCEEDED(this['users/selectedUserIds'], 
                            this.seatCount, this.activeUsers)); 
                    }
                    else {
                        this.showModal(MODAL_MESSAGE.ERROR(`${STATUS.ACTION[this.currentStatus]} action`));
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.userManage {
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    margin-left: 56px;
    max-width: 100%;
    margin-right: 129px;
    padding-bottom: 144px;
}

.actionButton {
    margin-right: 16px;
    padding-left: 0px;
}

.tableToolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 32px;   
}

.releaseSection {
    display: flex;
    align-items: center;
    flex-direction: column;
}
</style>