import { get } from '@/api/rest.utility';
import { URLS, ERROR_MESSAGE } from '@/components/shared/constants';
import { Account } from '@/model/Account';
import Cookies from 'js-cookie';
export const SET_ADMIN_FIRST_NAME = 'SET_ADMIN_FIRST_NAME';
export const SET_ADMIN_LAST_NAME = 'SET_ADMIN_LAST_NAME';
export const SET_ADMIN_ALL_MANAGED_ACCOUNTS = 'SET_ADMIN_ALL_MANAGED_ACCOUNTS';
export const SET_ADMIN_UTD_ID = 'SET_ADMIN_UTD_ID';
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';

export const admin = {
    namespaced: true,
    state: () => ({
        adminFirstName: '',
        adminLastName: '',
        adminUtdId: 0,
        allManagedAccounts: [
            new Account()
        ], 
        selectedAccount: new Account()
    }),
    getters: {
        adminFirstName: state => state.adminFirstName,
        adminLastName: state => state.adminLastName,
        adminUtdId: state => state.adminUtdId,
        allManagedAccounts: state => state.allManagedAccounts,
        selectedAccount: state => state.selectedAccount, 
        selectedAccountId: state => state.selectedAccount.accountId
    },
    mutations: { 
        [SET_ADMIN_FIRST_NAME]: (state, adminFirstName) => state.adminFirstName = adminFirstName,
        [SET_ADMIN_LAST_NAME]: (state, adminLastName) => state.adminLastName = adminLastName,
        [SET_ADMIN_UTD_ID]: (state, adminUtdId) => state.adminUtdId = adminUtdId,
        [SET_ADMIN_ALL_MANAGED_ACCOUNTS]: (state, accounts) => state.allManagedAccounts = accounts, 
        [SET_SELECTED_ACCOUNT]: (state, selectedAccount) => state.selectedAccount = selectedAccount
    },
    actions: { 
        async getAdminIdentifier({commit}) {
            const url = URLS.LOGGED_IN_USER_URL;
            const token = Cookies.get('authToken');
            await get(url, token).then((admin) => {
                commit(SET_ADMIN_UTD_ID, admin.utdId);
                commit(SET_ADMIN_FIRST_NAME, admin.firstName);
                commit(SET_ADMIN_LAST_NAME, admin.lastName);
            });
        },
        async getAllManagedAccounts({commit}) {

            const url = URLS.ACCOUNT_URL;
            const token = Cookies.get('authToken');
            try {
                const accounts = await get(url, token);
                //TODO: In later ticket, remove this check as admins in future will be able to manage multiple accounts. 
                if (accounts.length != 1) {
                    commit('SET_ERROR_STATE', true, { root: true });
                    commit('SET_ERROR_TITLE', ERROR_MESSAGE.AUTHORIZATION_FAILED.title, { root: true });
                    commit('SET_ERROR_BODY', ERROR_MESSAGE.AUTHORIZATION_FAILED.body, { root: true });
                }
                else { 
                    commit(SET_ADMIN_ALL_MANAGED_ACCOUNTS, accounts);
                    /*TODO: This works for now, but in the future, 
                            an admin could have more than one account.
                            Admins with multiple accounts should be able to select their account after they've logged in
                            but before the manage page loads*/
                    if(accounts.length === 1) { 
                        commit(SET_SELECTED_ACCOUNT, accounts[0]);
                    }
                }
            }
            catch(error) {
                commit('SET_ERROR_STATE', true, { root: true });
                commit('SET_ERROR_TITLE', ERROR_MESSAGE.AUTHORIZATION_FAILED.title, { root: true });
                commit('SET_ERROR_BODY', ERROR_MESSAGE.AUTHORIZATION_FAILED.body, { root: true });
            }          
        }, 
        clearAdminStore({commit}) {
            commit(SET_ADMIN_FIRST_NAME, '');
            commit(SET_ADMIN_LAST_NAME, '');
            commit(SET_ADMIN_ALL_MANAGED_ACCOUNTS, []);
            commit(SET_ADMIN_UTD_ID, 0);
            commit(SET_SELECTED_ACCOUNT, new Account());
        }
    }
};