<template>
  <div :class="`userManagementConsole${isModalVisible ? ' modalVisible' : ''}`">
    <console-header />
    <router-view />
    <console-footer />
  </div>
</template>

<script>
import ConsoleHeader from './components/shared/ConsoleHeader.vue';
import ConsoleFooter from './components/shared/ConsoleFooter.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: { ConsoleHeader, ConsoleFooter },
    computed: mapGetters([
        'isModalVisible'
    ]),
    mounted() {
        this.getClientVersion();
    },
    methods: {
        ...mapActions('supportTag', ([
            'getClientVersion'
        ]))
    }
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}

* {
  font-family: 'Noto Sans', sans-serif
}

.modalVisible {
  z-index: 1000000;
}

.userManagementConsole {
  position: relative;
  min-height: 100%;
}
</style>