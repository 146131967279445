export const SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED';

export const session = {
    namespaced: true,
    state: () => ({ 
        sessionExpired: false   
    }),
    getters: {
        sessionExpired: state => state.sessionExpired
       
    },
    mutations: { 
        [SET_SESSION_EXPIRED]: (state, sessionExpired) => state.sessionExpired = sessionExpired    
    },
    actions: {  
        clearSessionStore({commit}) {
            commit(SET_SESSION_EXPIRED, false);         
        }
    }
};